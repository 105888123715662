import React from 'react';
import { Box, Text } from '@sanch941/lib';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Container } from '@ui';
import { Card } from './card';
import { VacancyGetPosition } from '@apis/vacancy';

export const OurVacanciesTemplate = () => {
    const { t } = useTranslation();
    const positions: VacancyGetPosition[] = t('vacancies.positions');

    return (
        <Container>
            <Box $top={30} $bottom={30}>
                <Text fz={24} $color="#FF2CB7">
                    {t('vacancies.ourVacanciesTitle')}
                </Text>

                <Box
                    sm={{
                        $flex: true,
                        fxw: 'wrap',
                        jc: 'space-between'
                    }}
                >
                    {positions.map((card, idx) => (
                        <Box $width="100%" key={idx} $top={20}>
                            <Card {...card} />
                        </Box>
                    ))}
                </Box>
            </Box>
        </Container>
    );
};
