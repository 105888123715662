import React from 'react';
import {
    Box,
    IsDesktop,
    media,
    pxRem,
    Text,
    StrapiImage,
    StrapiListItemFromRes
} from '@sanch941/lib';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { RichText } from '@lib';
import styled from 'styled-components';

export const WeOfferTemplate = () => {
    const { t } = useTranslation();
    const offers: StrapiListItemFromRes[] = t('vacancies.weOffer.offers');

    return (
        <Box $top={30} $bottom={30}>
            <StyledContainer>
                <Text
                    fw={700}
                    fz={24}
                    $color="#FF2CB7"
                    ta="center"
                    sm={{ ta: 'left' }}
                >
                    {t('vacancies.weOffer.title')}
                </Text>

                <Box $top={30}>
                    {offers.map(({ rich_name }, idx) => (
                        <StyledDescriptionContainer key={idx}>
                            <RichText
                                fz={16}
                                lh={19}
                                $color="white"
                                sm={{ fz: 18, lh: 21 }}
                                text={rich_name}
                            />
                        </StyledDescriptionContainer>
                    ))}
                </Box>

                <IsDesktop>
                    <StyledBag
                        widthOnScreen={{ common: 508 }}
                        {...t('vacancies.weOffer.img')}
                    />
                </IsDesktop>
            </StyledContainer>
        </Box>
    );
};

const StyledContainer = styled.div`
    background: linear-gradient(86.74deg, #2d0877 1.54%, #1d0e32 100%);
    border-radius: 10px;
    padding: ${pxRem(30)} ${pxRem(36)};
    position: relative;

    ${media.md} {
        padding: ${pxRem(40)} ${pxRem(32)};
    }
`;

const StyledDescriptionContainer = styled(Box)`
    padding-left: ${pxRem(16)};
    position: relative;
    z-index: 2;

    &:not(:first-child) {
        margin-top: ${pxRem(10)};
    }

    &::before {
        content: '';
        height: ${pxRem(6)};
        width: ${pxRem(6)};
        background-color: #ff2cb7;
        border-radius: 100%;
        position: absolute;
        top: ${pxRem(6)};
        left: 0;
    }

    ${media.md} {
        width: ${pxRem(740)};
    }
`;

const StyledBag = styled(StrapiImage)`
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    z-index: 1;
`;
