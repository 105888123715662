import { pxRem, StrapiListItemFromRes } from '@sanch941/lib';
import React, { FC } from 'react';
import styled from 'styled-components';

export const Keywords: FC<ComponentProps> = ({ keywords }) => {
    return (
        <StyledContainer>
            {keywords.map(({ name }, idx) => (
                <StyledCard key={idx}> {name} </StyledCard>
            ))}
        </StyledContainer>
    );
};

interface ComponentProps {
    keywords: StrapiListItemFromRes[];
}

const StyledContainer = styled.div`
    display: inline-flex;
    flex-wrap: wrap;
    margin: -12px 0 0 -12px;
    width: calc(100% + 12px);
`;

const StyledCard = styled.div`
    height: ${pxRem(28)};
    background-color: #9d7fd9;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 12px 0 0 12px;
    padding: 0 ${pxRem(10)};
    color: white;
    font-size: ${pxRem(14)};
    line-height: ${pxRem(16)};
`;
