import { Box, media, pxRem, Text } from '@sanch941/lib';
import React, { FC } from 'react';
import styled from 'styled-components';
import { Keywords } from './keywords';
import { VacancyGetPosition } from '@apis/vacancy';
import { RichText } from '@lib';

export const Card: FC<ComponentProps> = ({
    profession,
    rich_sections,
    skills
}) => {
    return (
        <StyledContainer>
            <Text fw={700} $color="#470BBE" fz={24} lh={28}>
                {profession}
            </Text>

            <Box $top={25}>
                {rich_sections.map(({ rich_name, rich_description }, idx) => (
                    <StyledSection key={idx}>
                        <RichText fz={14} lh={16} fw={700} text={rich_name} />

                        <Box $top={10}>
                            <GrayText key={idx} text={rich_description} />
                        </Box>
                    </StyledSection>
                ))}
            </Box>

            <Box $top={20}>
                <Keywords keywords={skills} />
            </Box>
        </StyledContainer>
    );
};

interface ComponentProps extends VacancyGetPosition {}

const StyledContainer = styled.div`
    background: #f4f4f4;
    border-radius: 10px;
    padding: ${pxRem(30)} ${pxRem(11)};

    ${media.md} {
        padding: ${pxRem(30)} ${pxRem(28)};
        width: 32.5%;
    }
`;

const GrayText = styled(RichText).attrs({
    fz: 14,
    lh: 16,
    $color: '#626262'
})`
    &:not(:first-child) {
        margin-top: ${pxRem(20)};
    }
`;

const StyledSection = styled.div`
    &:not(:first-child) {
        margin-top: ${pxRem(20)};
    }
`;
