import React from 'react';
import { Box } from '@sanch941/lib';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { CommonTemplate, Container } from '@ui';
import { OurVacanciesTemplate } from './templates/our-vacancies/our-vacancies';
import { WeOfferTemplate } from './templates/we-offer';
import { RichText } from '@lib';

export const VacanciesPage = () => {
    const { t } = useTranslation();

    return (
        <CommonTemplate>
            <Box $top={26}>
                <Container>
                    <RichText
                        fw={700}
                        $color="#470BBE"
                        fz={24}
                        lh={28}
                        ta="center"
                        sm={{
                            fz: 32,
                            lh: 38
                        }}
                        text={t('vacancies.rich_title')}
                    />
                </Container>

                <Container mobilePadding={false}>
                    <WeOfferTemplate />
                </Container>

                <OurVacanciesTemplate />
            </Box>
        </CommonTemplate>
    );
};
