import { graphql } from 'gatsby';
import { VacanciesPage } from '../page-templates/vacancies/vacancies';

export default VacanciesPage;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
